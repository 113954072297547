@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    max-width: 100svw;
}

a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
}
a:hover {
    color: inherit;
    text-decoration: none;
    text-decoration-color: inherit;
    transition: 0.3s;
}

h1, h2, h3, h4, h5, h6, p, a, div, body, html, li, ul, ol, span {
    font-family: "Manrope", sans-serif !important;
}

.span-as-pre {
    white-space: pre;
    font-family: monospace !important;
    font-weight: bold;
}

.scrollbarOrari {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: lightgray transparent;
}

.hoverScale {
    cursor: pointer;
    transform: scale(1);
    transition: 0.3s;
}
.hoverScale:hover {
    transform: scale(0.95);
    transition: 0.3s;
}